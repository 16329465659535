import React, { Component } from 'react';
import Header from '../components/header';
import HeaderArc from '../components/headerArc';
import Footer from '../components/footer';

export default class About extends Component {
    render() {
        return(
            <React.Fragment>

                <section className={`blue-content`}>
                    <Header />
                    <HeaderArc />
                </section>
                

                <div id="content">
                    <section>
                        <div className={`wrapper`}>

                            <div className={`grid--dual`}>
                                <div>
                                    <h2>About us</h2>
                                    <h3>We've been around since 2011</h3>
                                    <p>
                                        METAR Reader was created in 2011 to satisfy our own needs for a handy app to provide us with preflight weather information. Due to the huge success of the app we also created an online tool at metarreader.com
                                    </p>
                                    <p>
                                        We've listened to your requests and feedback and thanks to that we launched version 2.0 with new features and a completely rewritten and much more advanced decode engine.
                                    </p>
                                    <p>
                                        Always feel free to send us a message through our contact page.
                                    </p>
                                    <div className={`features--badge`}>
                                        <a href="https://itunes.apple.com/app/metar-reader/id465765574?mt=8" target="_blank">
                                            <img src="assets/images/app-store-badge-large.png" height="54" width="162" />
                                        </a>
                                    </div>
                                </div>

                                <div  className={`about-screenshot`}>
                                    <img width="100%" src="assets/images/metar-reader-2011.jpg" />
                                    METAR Reader back in 2011
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </React.Fragment>
        )
    }
}