import React, { useState, useEffect } from 'react';

export default () => {

    const [copied, setCopied] = useState("");
    
    useEffect(() => {
        setTimeout(() => {setCopied(``)}, 300);
    }, [copied])

    return (
        <section className={`panel panel--decode support`}>
            <h1>Support METAR Reader</h1>
            <p>Thank you for using METAR Reader! If you like using our service and want to support us, please consider donating.</p>

            <div className={`support-crypto`}>
                <strong>Bitcoin (BTC)</strong>
                <pre>bc1q6zavl5jcxl6tg67yus0zttn9anunmnp9jzulv7</pre>
                <button className={`${copied == `BTC` ? `copied` : ``} ${navigator.clipboard == undefined ? `hidden` : ``}`} onClick={() => { copyToClipboard(`bc1q6zavl5jcxl6tg67yus0zttn9anunmnp9jzulv7`); setCopied(`BTC`);}}>Copy BTC address</button>
            </div>
            <div className={`support-crypto`}>
                <strong>Ethereum (ETH)</strong>
                <pre>0x52645e819995b6f23ba48c754646914DE35C066f</pre>
                <button className={`${copied == `ETH` ? `copied` : ``} ${navigator.clipboard == undefined ? `hidden` : ``}`} onClick={() => { copyToClipboard(`0x52645e819995b6f23ba48c754646914DE35C066f`); setCopied('ETH');}}>Copy ETH address</button>
            </div>
            <div className={`support-crypto`}>
                <strong>Dogecoin (DOGE)</strong>
                <pre>D7hGT2Wgg4rPYRPiMUuAmpHH6NDh3o1ME5</pre>
                <button  className={`${copied == `DOGE` ? `copied` : ``} ${navigator.clipboard == undefined ? `hidden` : ``}`} onClick={() => {copyToClipboard(`D7hGT2Wgg4rPYRPiMUuAmpHH6NDh3o1ME5`); setCopied('DOGE');}}>Copy DOGE address</button>
            </div>

        </section>
    )
}

const copyToClipboard = function(text) {
    var clipboard = navigator.clipboard;
    if (clipboard == undefined) {return;}

    navigator.clipboard.writeText(text).then(() => {
        console.log("Written");
    });
}