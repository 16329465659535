import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import isoCountries from '../helpers/isoCountries';
import Header from '../components/header';
import HeaderArc from '../components/headerArc';
import Footer from '../components/footer';
import latinize from '../helpers/latinize';

class Countries extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const countries = isoCountries();
        let firstLetter = '';
        const countryList = Object.keys(countries).map(country => {
            const newFirstLetter = countries[country].charAt(0).latinize();
            let seperator = null;
            if (firstLetter !== newFirstLetter) {
                seperator = <div className={`countries--letter`}><h1>{newFirstLetter}</h1></div>;
                firstLetter = newFirstLetter;
            }
            return (
                <React.Fragment>
                    {seperator}
                    <Link key={country} title={countries[country]} to={`/stations/${country}`}>{countries[country]}</Link>
                </React.Fragment>
            )
        });

        return (
            <React.Fragment>
                <section className={`blue-content`}>
                    <Header />
                    <HeaderArc />
                </section>

                <div id="content">
                    <section>
                        <div className={`wrapper`}>
                            

                        <div className={`countries--grid`}>
                            { countryList }
                        </div>


                        </div>
                    </section>
                </div>

                <Footer></Footer>
            </React.Fragment>
        )
    }

}

export default withRouter(Countries);





