import React, { Component } from 'react';
import moment from 'moment';

export default class TimeAgo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timeSinceIssued: '06/06/2018 00:00',
            displayRawTime: false,
            interval: null
        }

        // MomentJS defaults rounding 45+ minutes to an hour. Set it to 60+ minutes.
        moment.relativeTimeThreshold('m', 60);

        this.updateTimeStamp = this.updateTimeStamp.bind(this);
        this.updateInterval = this.updateInterval.bind(this);
    }

    updateInterval() {
        const x = setInterval(() => this.updateTimeStamp(), 60000);
        this.setState({interval: x});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.issued !== this.props.issued) {
            this.updateTimeStamp();
        }
    }

    componentDidMount() {
        this.updateTimeStamp();
        this.updateInterval();
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    updateTimeStamp() {

        // Sometimes wx providers mark the upload date of submission (not the wx report itself) in the future. Maybe due to timecode errors on their computer system.
        // If the date is in the future, don't show it at all.
        if (new Date(`${this.props.issued} UTC`) > Date.now()) {
            this.setState({timeSinceIssued: '...'});
            return;
        }

        if (this.props.issued) {
            const timeAgoFormat = moment.utc(this.props.issued, 'YYYY/MM/DD hh:mm').fromNow();
            this.setState({timeSinceIssued: timeAgoFormat}); 
        } else {
            this.setState({timeSinceIssued: ''});
        }
    }

    getLocalizedTimeString() {
        const issued = new Date(this.props.issued);
        const minutes = `0${issued.getMinutes()}`.slice(-2);
        return `${issued.getFullYear()}/${issued.getMonth() + 1}/${issued.getDate()} ${issued.getHours()}:${minutes}`;
    }

    render() {
        return <span className={`timestamp`}>{this.state.timeSinceIssued}</span>
    }
}