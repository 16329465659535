import React from 'react';

export default ({height, width, centered = false}) => {
    const style = {
        height: height,
        width: width,
        // paddingLeft: width/2,
        // paddingRight: width/2,
        // transform: {scaleY: 0.9}
    }

    if (centered) {
        style.marginLeft = 'auto';
        style.marginRight = 'auto';
    }
    return(
        <span className={`placeholder`} style={style}>&nbsp;</span>
    )
}