import React, { Component } from 'react';
import { ENDPOINTS } from '../API/constants';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import isoCountries from '../helpers/isoCountries';
import Header from '../components/header';
import HeaderArc from '../components/headerArc';
import Footer from '../components/footer';
import latinize from '../helpers/latinize';
import slugify from '../helpers/slugify';

class Stations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stations: [],
            doneLoading: false
        }

        this.renderStations = this.renderStations.bind(this);
    }

    componentDidMount() {
        const { location, match, history } = this.props;
        this.getIcaos();
    }

    async getIcaos() {
        const { location, match, history } = this.props;
        const icaos = await axios.get(`${ENDPOINTS.icaos.get}/${match.params.country}`);
        this.setState({stations: icaos.data, doneLoading: true});
    }

    renderStations() {
        const icaoList = this.state.stations.map(station => {
            return (
                <React.Fragment>
                    <Link key={station.icao} title={station.name} to={`/${station.icao}/${slugify(station.name)}`}>{station.name || `Unknown (${station.icao})`}</Link>
                </React.Fragment>
            )
        });
        return icaoList;
    }

    render() {

        

        return (
            <React.Fragment>
                <section className={`blue-content`}>
                    <Header />
                    <HeaderArc />
                </section>

                <div id="content">
                    <section>
                        <div className={`wrapper`}>
                            <section className={`breadcrumb`}>
                                <nav>
                                    <Link to={`/stations`}>Stations</Link> / {isoCountries()[this.props.match.params.country]}
                                </nav>
                                <span>{this.state.stations.length} result(s)</span>
                            </section>
                            

                        <div className={`countries--grid`}>
                            {this.renderStations()}
                        </div>
                        {(this.state.stations.length === 0 && this.state.doneLoading) && (
                            <div>
                                <h1>No results</h1>
                                <p>Sorry, we couldn't find any results for {isoCountries()[this.props.match.params.country]}</p>
                            </div>
                        )}


                        </div>
                    </section>
                </div>

                <Footer></Footer>
            </React.Fragment>
        )
    }

}

export default withRouter(Stations);





