import React, { Component } from 'react';
import { SlowBuffer } from 'buffer';

export default class Decoded extends Component {

    constructor(props) {
        super(props);

        String.prototype.capitalize = function() {
            return this.charAt(0).toUpperCase() + this.slice(1);
        }

        String.prototype.replaceAll = function(search, replacement) {
            var target = this;
            return target.split(search).join(replacement);
        };
    }

    render() {
        
        const titles = {
            main: '',
            rmk: 'Remarks',
            tempo: 'Temporary',
            becmg: 'Becoming'
        }

        const categories = Object.keys(this.props.report);

        return(
            categories.map((categoryKey, index) => {
                const title = categoryKey.toLowerCase() !== 'main' && <h3>{titles[categoryKey]}</h3>
                const item = this.props.report[categoryKey];

                return (
                    <div key={index} className={`decoded-grid`}>
                        { title }
                        { item.map((itm) => this.renderItem(itm)) }
                    </div>
                );
            })
        );
    }

    renderItem(entry) {
        //Render Main items
        return Object.keys(entry).map((item, index) => { return (
            //console.log('entry', entry);
            
            <section key={index}>
                    <h2 className={`titles`}>{item}</h2>
                    <div className={`decoded--subgrid`}>
                        { entry[item].map((subItem, n) => {
                            const replacedItem = subItem.decodeResult.replaceAll('\\n', `\n`);
                            return (
                                <div key={n}>
                                <React.Fragment>
                                    <span className={`original`}>{subItem.originalChunk.toUpperCase()}</span>
                                    <span className={`decoded`}>{replacedItem.capitalize()}</span>
                                </React.Fragment>
                                </div>
                            )}
                        )}
                    </div>
            </section>
        )})
    }
}