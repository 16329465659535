import React, { Component } from 'react';
import SearchInput from './searchInput';
import { Link } from 'react-router-dom';

export default class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
    }

    render() {
        const classesMenu = [ 'main-navigation'];
        const classesHeader = [ ];

        if (this.state.menuOpen) {
            classesMenu.push('menu-open');
            classesHeader.push('menu-open');
        }

        return(
            <div className={`wrapper`}>
                <section id={`header`} className={classesHeader.join(' ')}>
                <Link to={`/`}><img src={`/assets/images/metar-reader-logo.png`} alt={`METAR Reader logo`} title={`METAR Reader`} width={439} /></Link>
                </section>
                <nav className={classesMenu.join(' ')}>
                    <img className={`menu`} src="/assets/images/menu.svg" onClick={() => this.setState({menuOpen: !this.state.menuOpen})} />
                    <Link to={`/`}>Home</Link>
                    <Link to={`/about`}>About Us</Link>
                    <Link to={`/stations`}>All Stations</Link>
                    <Link to={`/contact`}>Contact</Link>
                    {/* <a href={`/eula`}>Terms of Use</a> */}
                    <a href={`https://itunes.apple.com/app/metar-reader/id465765574?mt=8`} target="_blank"><img src="/assets/images/app-store-badge-small.png" /></a>
                </nav>
            </div>
        )

    }
}