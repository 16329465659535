export const getIssuedAt = (issued, wxreport) => {
    
    if (issued === null || wxreport === null || issued === '' || wxreport === '') {
        return '';
    }

    const timecode = wxreport.match(/(\d{2})(\d{2})(\d{2})Z/);

    if (timecode === null) {
        return '';
    }

    const wxTime = {
        day:    timecode[1],
        hour:   timecode[2],
        minute: timecode[3],
    }

    const wxtimestamp = issued.match(/(\d{4})\/(\d{2})\/(\d{2})\s[\d{2}:\d{2}]/);
    
    if (wxtimestamp === null) { 
        return '';
    }
    
    const issuedTime = {
        year:   wxtimestamp[1],
        month:  wxtimestamp[2],
        day:    wxtimestamp[3],
    }

    const t = {
        year: issuedTime.year,
        month: issuedTime.month,
        day: wxTime.day,
        hour: wxTime.hour,
        minute: wxTime.minute
    }

    return `${t.year}/${t.month}/${t.day} ${t.hour}:${t.minute} UTC`;
}