import React from 'react';

export default () => {
    return(
        <div id="content">
            <section>
                <div className={`wrapper`}>

                    <div className={`grid--dual`}>
                        <div>
                            <h2>Out now on the App Store</h2>
                            <h3>More features, more awesome!</h3>
                            <p>
                                METAR Reader is one of the most popular apps to fetch and decode your preflight weather reports and is used by pilots, aviation corporations, flight schools, students, cockpit crew, flight ops and flight simmers all around the world.
                            </p>
                            <p>
                            With the new METAR Reader app comes a completely rewritten decode engine that is actively being updated so it can decode most observations in even the most sophisticated METAR reports provided by thousands of airports and weather stations around the world.
                            </p>
                            <div className={`features--badge`}>
                                <a href="https://itunes.apple.com/app/metar-reader/id465765574?mt=8" target="_blank">
                                    <img src="/assets/images/app-store-badge-large.png" height="54" width="162" />
                                </a>
                            </div>
                        </div>

                        <div  className={`phone-screenshots`}>
                            <div>
                                <div className={`phone-graphic`}>
                                    <img width="100%" src="/assets/images/metar-decoder-app-reader-1.png" />
                                </div>
                                <div className={`phone-graphic`}>
                                    <img width="100%"  src="/assets/images/metar-decoder-app-reader-2.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`section--keyfeatures`}>
                <div className={`wrapper`}>
                    <div>
                        <h2>Key Features</h2>
                        <h3>Get the full experience on mobile</h3>
                        <ul className={`blue-bullet`}>
                            <div>
                                <li>Get your latest METAR and TAF reports</li>
                                <li>Decode METAR weather forecasts</li>
                                <li>Learn from dissected decoded reports</li>
                                <li>Discover airports and weather stations near you</li>
                            </div>
                            <div>
                                <li>Your favorite TAF and/or METAR reports on the front page</li>
                                <li>Share weather reports with your friends or social media</li>
                                <li>Intuitive, fast and responsive user interface</li>
                                <li>Native, lightweight, incredibly fast</li>
                            </div>
                        </ul>
                    </div>
                </div>
            </section>

            <section>
                <div className={`wrapper`} style={{textAlign: 'center'}}>
                    <div className={`video-container`}>
                        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/9bGsL8nGh7k" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                </div>
            </section>
        </div>
    )
}