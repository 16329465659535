import React, { Component } from 'react';
import SearchInput from '../components/searchInput';
import axios from 'axios';
import { ENDPOINTS } from '../API/constants';
import WxReport from '../components/wxreport';
import Header from '../components/header';
import HeaderArc from '../components/headerArc';
import Decoded from '../components/decoded';
import Placeholder from '../components/placeholder';
import AppFeatures from '../components/app-features';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';
import Support from '../components/support';

import ReactGA from 'react-ga';

export default class Station extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isloading: false,
            station: {},
            weather: {},
            decodeMETAR: false,
        }

        this.loadWeather = this.loadWeather.bind(this);
        this.decodeMETAR = this.decodeMETAR.bind(this);
    }

    componentDidMount() {
       this.loadStation();
       this.loadWeather();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.icao !== this.props.match.params.icao) {
            this.loadStation();
            this.loadWeather();
            this.setState({
                station: {},
                weather: {},
                decodeMETAR: false
            });
        }
    }

    async loadStation() {
        const {icao} = this.props.match.params;

        this.setState({isloading: true});
        try {
            if (icao.length !== 4) {
                throw Error('Invalid identifier');
            }
            const response = await axios.get(`${ENDPOINTS.station.get}/${icao}`);
            this.setState({station: response.data});

            document.title = `${icao} - ${response.data.name}, ${response.data.country} | METAR Reader`;
            document.querySelector('meta[name="description"]').content = `Most recent METAR and TAF reports for ${icao} (${response.data.name}). Decode with one click! | METAR Reader, Your Preflight Weather Companion.`;

        } catch(error) {
            console.error(error);
        }
        this.setState({isloading: false});

    }

    async loadWeather() {
        const {icao} = this.props.match.params;
        try {
            if (icao.length !== 4) {
                throw Error('Invalid identifier');
            }
            const response = await axios.get(`${ENDPOINTS.weather.get}/${icao}`);
            this.setState({weather: response.data});

        } catch(error) {
            console.error(error);
        }
    }

    renderNoData() {
        if (this.state.isloading) {
            return (
                <div>loading</div>
            )
        } else {
            return (
                <div>
                    No station found
                </div>
            );
        }
    }

    decodeMETAR(e) {
        e.preventDefault();

        this.setState({decodeMETAR: true});
        
        ReactGA.initialize('UA-8292745-3');
        ReactGA.event({
            category: 'Decode',
            action: 'Decoded METAR',
            label: `User decoded METAR for ${this.state.station.icao || 'unknown'}`
          });
    }

    renderCountry() {
        const { icao, name, country, iso } = this.state.station;
        if (this.state.station.country === null) return null;
        if (iso !== null) {
            return <Link to={`/stations/${iso}`} title={`List of airports in ${country}`}>{country}</Link>
        } else {
            return country;
        }

    }

    renderData() {
        const { icao, name, country, iso } = this.state.station;
        const { M, T } = this.state.weather || {M: null, T: null};
        return (
            <div className={`wrapper`}>
                <div className={`station`}>
                    <div className={`grid-item`}>
                        <SearchInput history={this.props.history} />
                        <section className={`panel`}>
                            <div className={`panel--inner`}>
                                <div className={`station--airport`}>
                                    <h1>{name || <Placeholder width={250} />}</h1>
                                    <span>{country && this.renderCountry() || <Placeholder width={150} />}</span>
                                </div>

                                <h2 className={`titles`}>Weather Reports</h2>
                                <section className={`block`}>
                                    <WxReport report={M} type={`M`} name={name} />
                                </section>
                                <section className={`block`}>
                                    <WxReport report={T} type={`T`} name={name} />
                                </section>
                            </div>
                            {/* <a href="#content">
                                <section className={`download-app`}>
                                    <h1>Download the METAR Reader app</h1>
                                    More features, more awesome!
                                    <div>
                                        <img src="assets/images/arrow-down.svg" />
                                    </div>
                                </section>
                            </a> */}
                        </section>
                    </div>
                    
                    <div className={`grid-item`}>
                        <section className={`panel panel--decode`}>
                        {(this.state.weather !== undefined && this.state.weather.M !== undefined && this.state.decodeMETAR) &&
                            <Decoded report={this.state.weather.M.decoded} /> ||
                            <div className={`decoder--empty`}>
                                <h1>Decode METAR</h1>
                                <p>Use our advanced METAR Decoder and learn how to read weather reports in no-time.</p>
                                <button className={`blue-button`} onClick={this.decodeMETAR}>Decode METAR</button>
                            </div>
                        }
                        </section>

                        
                            <Support />
                        
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return(
            <React.Fragment>
                <section className={`blue-content`}>
                    <Header />
                    { this.renderData() }
                    <HeaderArc />
                </section>
                <AppFeatures />
                <Footer />
            </React.Fragment>
        )
    }
}