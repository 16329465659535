import React, { Component } from 'react';
import Header from '../components/header';
import HeaderArc from '../components/headerArc';
import Footer from '../components/footer';

export default class Contact extends Component {
    render() {
        return(
            <React.Fragment>

                <section className={`blue-content`}>
                    <Header />
                    <HeaderArc />
                </section>

                <div id="content">
                    <section>
                        <div className={`wrapper`}>
                            <h1>Contact</h1>
                            <p>We'd love to hear your feedback, suggestions and questions.<br />
                            Feel free to contact us at <a href="mailto:support@metarreader.com">support@metarreader.com</a></p>
                        </div>
                    </section>
                </div>

                <Footer></Footer>
            </React.Fragment>
        )
    }
}