import React, { Component } from 'react';
import SearchInput from '../components/searchInput';
import Header from '../components/header';
import HeaderArc from '../components/headerArc';
import Footer from '../components/footer';
import AppFeatures from '../components/app-features';
import Support from '../components/support';

export default class Home extends Component {

    componentDidMount() {
        document.title = `METAR Reader - Your preflight weather companion`;
    }

    render() {
        return(
            <React.Fragment>
                
                <section className={`blue-content`}>
                    <Header />        

                    <div className={`wrapper`}>
                        <div className={`station`}>
                            <div className={`grid-item`}>
                                <SearchInput history={this.props.history} />
                                <section className={`panel`}>
                                    <div className={`panel--inner empty-weather-panel`}>
                                        Search for an airport or weather station to retrieve its METAR and TAF weather report.
                                    </div>
                                </section>
                            </div>
                            <div className={`grid-item`}>
                                <Support />
                            </div>
                        </div>
                    </div>

                    <HeaderArc />
                </section>

                <AppFeatures />
                <Footer />
                
            </React.Fragment>
        )
    }
}