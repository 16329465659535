import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, withRouter, Redirect } from 'react-router-dom';
import withTracker from './components/withTracker';
import ReactGA from 'react-ga';

import Home from './pages/home';
import Station from './pages/station';
import Contact from './pages/contact';
import About from './pages/about';
import Countries from './pages/countries';
import Stations from './pages/stations';

function initializeReactGA() {
  ReactGA.initialize('UA-8292745-3');
}

export default () => {

  initializeReactGA();

  return(
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={withTracker(Home)} />
        <Route exact path="/app" component={withTracker(Home)} />
        <Route exact path="/about" component={withTracker(About)} />
        <Route exact path="/stations" component={withTracker(Countries)} />
        <Route path="/stations/:country?" component={withTracker(Stations)} />
        <Route exact path="/contact" component={withTracker(Contact)} />
        <Route exact path="/:icao([a-zA-Z0-9]{4})/:name?" component={withTracker(Station)} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
}
