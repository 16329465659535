const API_URL = 'https://api.metarreader.com/web';

export const ENDPOINTS = {
    search: {
        suggestions: {
            get: `${API_URL}/suggestions`
        }
    },
    station: {
        get: `${API_URL}/station`
    },
    weather: {
        get: `${API_URL}/weather`
    },
    icaos: {
        get: `${API_URL}/icaos`
    }
}