import React, { Component } from 'react';
import Timeago from './timeago';
import Placeholder from '../components/placeholder';
import { getIssuedAt } from '../helpers/issued';

export default class WxReport extends Component {

    static defaultProps = {
        report: {
            issued: null,
            report: null,
            available: null
        }
    }

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    renderTAF(report) {
            // Here we will create new lines for every remark, tempo, etc.
            const split = report.split('\\n');
        
            return split.map((item, index) => {
                // Remarks, Tempo's, etc.
                if (index > 0) {
        
                    // Make first item bold
                    const splitted = item.split(" ").map((item, i) => { 
                        if (i === 0) { 
                            return (<span key={i} className={`report--bold`}>{item} </span>)
                        } else {
                            return (<span key={i}>{item} </span>)
                        }
                    });
        
                    return (
                        <div className={`report--tafline`} key={index}>
                            <span className={`report`}>{ splitted }</span>
                        </div>
                    );
                } else {
                    // Main report, first part.
                    return (
                        <div key={index}>
                            <span className={`report`} >{ item }</span>
                        </div>
                    );
                }
            });
    }


    render() {
        
        // placeholder
        if (this.props.report === null || this.props.report.available === null) {
            return (
                <section className={`report`}>
                    <div><Placeholder width={60} /></div>
                    <div><Placeholder width={'80%'} /></div>
                    <div><Placeholder width={'80%'} /></div>
                    <div><Placeholder width={100} /></div>
                </section>  
            )
        }

        // No report available
        if (!this.props.report.available) {
            return <span>No {this.props.type === 'M' ? `METAR` : `TAF`} available for {this.props.name}</span>
        }

        const { issued, report } = this.props.report;
        const { type } = this.props;

        const reconstructedIssued = getIssuedAt(issued, report);

        // Render report
        if (type === 'M') {
            return (
                <section className={`report`}>
                    <h3>{ report && `METAR` }</h3>
                    <span className={`report--main`}>{ report }</span>
                    <Timeago issued={reconstructedIssued} />
                </section>  
            );
        } else {
            return (
                <section className={`report`}>
                    <h3>TAF</h3>
                    { this.renderTAF.bind(this)(report) }
                    <Timeago issued={reconstructedIssued} />
                </section>  
            );
        }
    }
}