import React, { Component } from 'react';
import { debounce } from 'lodash';
import axios from 'axios';
import { ENDPOINTS } from '../API/constants';

export default class SearchInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchPhrase: '',
            isFetchingSuggestions: false,
            results: [],
            focusedClass: '',
            selectIndex: null,
            showPopup: true,
        }

        // Bind Stations
        this.navigateToStation = this.navigateToStation.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onClick = this.onClick.bind(this);

        // Debounce Suggestions
        this.debouncedSearch = debounce(this.getSearchSuggestions, 500);
    }

    componentDidMount() {
        this.onKeydownHandler = this.searchInput.addEventListener('keydown', this.onKeyDown);
        this.documentClickHandler = document.addEventListener('click', this.onClick);
        this.searchInput.focus();
    }

    componentWillUnmount() {
        this.searchInput.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('click', this.onClick);
    }

    onClick(e) {
        if (e.target !== this.searchInput) {
            this.setState({
                showPopup: false
            })
        }
    }

    onKeyDown(e) {
        // No search results. Abort.
        if (this.state.results.length === 0) {
            return;
        }

        let newIndex;

        // keyCode 40 = upArrow. keyCode 38 = downArrow
        if (e.keyCode === 40) {
            newIndex = (this.state.selectIndex === null) ? 0 : this.state.selectIndex + 1;
        } else if (e.keyCode === 38) {
            newIndex = (this.state.selectIndex === null) ? this.state.results.length - 1 : this.state.selectIndex - 1;
        } else if (e.keyCode === 27) {
            this.setState({selectIndex: null, showPopup: false});
            return;
            // escape
        } else {
            return;
        }

        // Create new selectionIndex
        newIndex = newIndex % this.state.results.length;
        if (newIndex < 0) { newIndex += this.state.results.length }

        this.setState({
            selectIndex: newIndex,
            showPopup: true
        });
    }

    async getSearchSuggestions() {
        const { searchPhrase } = this.state;

        if (searchPhrase === '') {
            this.setState({
                results: [],
                showPopup: false,
                selectIndex: null
            });
            return;
        }

        // Search Indicator on
        this.setState({isFetchingSuggestions: true});

        
        try {
            // Get search suggestions from server
            const response = await axios.get(`${ENDPOINTS.search.suggestions.get}/${searchPhrase}`);
            this.setState({
                results: response.data,
                showPopup: true,
                selectIndex: null
            });
        } catch(error) {
            console.error(error);
        }

        // Search Indicator off
        this.setState({isFetchingSuggestions: false});
    }

    onSubmit(e) {
        e.preventDefault(); 
        if (this.state.selectIndex !== null) {
            const selectedSuggestion = this.state.results[this.state.selectIndex];
            this.navigateToStation(selectedSuggestion.icao);
        } else {
            if (this.state.searchPhrase.length === 4) {
                this.navigateToStation(this.state.searchPhrase);
            }
        }
    }

    onChange(e) {
        const { value } = e.target;
        if (value.trim() === '') {
            this.setState({
                searchPhrase: '',
                results: []
            });
            return;
        }
        this.setState({searchPhrase: value, selectIndex: null});
        this.debouncedSearch(value);
    }

    onFocus() {
        this.setState({
            focusedClass: `focused`
        });
    }

    onBlur() {
        this.setState({
            focusedClass: ``
        });
    }

    onMouseOver(index) {
        this.setState({
            selectIndex: index
        })
    }

    navigateToStation(icao) {
        this.props.history.push(`/${icao.toUpperCase()}`);
        this.setState({
            selectIndex: null,
            results: [],
            searchPhrase: ''
        });
    }
    

    renderSuggestions() {
        return this.state.results.map((result, index) => { 
            const classes = [`popup--row`];
            (index === this.state.selectIndex) && classes.push(`selected`);
            return (
            <div 
                className={classes.join(' ')}
                key={index} 
                onClick={() => this.navigateToStation(result.icao)}
                onMouseOver={() => this.onMouseOver(index)}
                >
                <span className={`popup--icao`}>{result.icao}</span>
                <div className={`popup--location`}>
                    <span className={`popup--name`}>{result.name}</span>
                    <span className={`popup--country`}>{result.country}</span>
                </div>
            </div>)
        })
    }

    render() {
        const { results, showPopup, focusedClass } = this.state;
        const classArray = [focusedClass];
        if (this.state.results.length > 0 && this.state.showPopup) {
            classArray.push('hasResults');
        }
        return(
            <form onSubmit={this.onSubmit.bind(this)}>
                <div id="searchsuggestions" className={classArray.join(' ')}>
                    <input 
                        ref={el => this.searchInput = el}
                        type="text" 
                        placeholder={`Search by name or ICAO`} 
                        onFocus={this.onFocus.bind(this)} 
                        onBlur={this.onBlur.bind(this)} 
                        onChange={this.onChange.bind(this)} 
                        style={(results.length === 0 || !showPopup) && {
                            borderBottomRightRadius: '6px', 
                            borderBottomLeftRadius: '6px'} || {}}
                        value={this.state.searchPhrase}
                        autoCorrect="off"
                        autoComplete="off"
                        autoCapitalize="off"
                        />
                    <div 
                        className="popup" 
                        style={((results.length === 0) || !showPopup) && { display: 'none'} || {}}>
                        { this.renderSuggestions.bind(this)() }
                    </div>
                </div>
            </form>
        )
    }
}